import useScreenTracking from 'hooks/useScreenTracking';
import { useEffect, useState } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import styles from './EmailForm.module.scss';

export default function EmailForm({ onNextStep, risk, calculateRisk }) {
  useScreenTracking('EmailSignup');

  useEffect(() => {
    calculateRisk();
  }, [calculateRisk]);

  let url = process.env.REACT_APP_MAILCHIMP_URL_HIGH;
  let tag = process.env.REACT_APP_MID_HIGH_RISK_TAG_ID;

  if (risk === 'low') {
    url = process.env.REACT_APP_MAILCHIMP_URL_LOW;
    tag = process.env.REACT_APP_LOW_RISK_TAG_ID;
  }

  return (
    <div className={styles.formWrapper}>
      <h3>Fyll i din e-postadress (valfritt)</h3>
      <p>Vill du få en kopia av ditt resultat på mejl?</p>

      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status }) => {
          return (
            <CustomForm
              onSubmit={(formData) => {
                subscribe(formData);
              }}
              status={status}
              onNextStep={onNextStep}
              tag={tag}
            />
          );
        }}
      />
    </div>
  );
}

const CustomForm = ({ onSubmit, status, onNextStep, tag }) => {
  const [email, setEmail] = useState('');
  useEffect(() => {
    if (status === 'success') {
      onNextStep();
    }
  }, [status, onNextStep]);

  const submit = (e) => {
    e.preventDefault();

    if (email.includes('@')) {
      onSubmit({
        EMAIL: email,
        tags: tag
      });
    } else {
      onNextStep();
    }
  };

  return (
    <div>
      <form onSubmit={submit}>
        <label htmlFor="email">E-postadress</label>
        <input type="email" id="email" className="mb-5" onChange={(e) => setEmail(e.target.value)} />
        <p>
          <a href="https://blodtrycksdoktorn.se/integritetspolicy/" target="_blank" rel="noreferrer noopener">
            Så behandlas dina personuppgifter
          </a>
        </p>
        <div className="center-button">
          <button type="submit">Fortsätt {!email.length ? 'utan e-post' : ''}</button>
        </div>
      </form>

      {status === 'error' ? (
        <div className="error-message">Något gick fel när e-postadressen skulle läggas till.</div>
      ) : null}
    </div>
  );
};
